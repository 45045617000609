<script lang="ts">
	import logo from '$lib/assets/logo.webp';
	import { displayStrings } from '$lib/i18n';
	import RightChevron from 'virtual:icons/mdi/chevron-right';

	const pageStrings = displayStrings.pages.root;
</script>

<svelte:head>
	<title>{pageStrings.title}</title>
	<meta name="description" content={pageStrings.description} />
</svelte:head>

<div
	class="flex h-full w-full flex-col items-center justify-center bg-gradient-to-r from-primary-300 to-secondary-100 px-10"
>
	<h1 class="flex items-center justify-center">
		<img class="w-3/4 md:w-128" alt={pageStrings.title} src={logo} />
	</h1>
	<h2 class="h4 mt-4 rounded-lg border border-primary-500 p-2 px-4 text-center text-primary-500">
		{pageStrings.subheader}
	</h2>
	<div class="flex flex-row gap-4">
		<a
			class="variant-filled-primary btn mt-24 flex items-center justify-between rounded py-1 font-semibold"
			href="/about"
		>
			{pageStrings.aboutButton}
		</a>
		<a
			class="variant-filled-primary btn mt-24 flex items-center justify-between rounded py-1 pr-1 font-semibold"
			href="/listings"
		>
			{pageStrings.ctaButton}
			<RightChevron class="ml-2 stroke-1" font-size="20" />
		</a>
	</div>
</div>
